<template>
  <div>
    <nav-bar/>
    <aside-menu
      :menu="menu"
      :class="{'has-secondary':!!menuSecondary}" />
    <router-view/>
    <footer-bar/>
    <overlay/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import Overlay from '@/components/Overlay'

export default {
  name: 'AdminLayout',
  components: {
    FooterBar,
    AsideMenu,
    NavBar,
    Overlay
  },
  data () {
    return {
      menuSecondary: null,
      menu: []
    }
  },
  mounted () {
    this.loadMenu()
  },
  methods: {
    loadMenu () {
      this.menu = [
        'Cargando menú...'
      ]
      this.menu = [
        [
          {
            to: '/',
            icon: 'desktop-mac',
            label: 'Dashboard'
          }
        ],
        'Eventos',
        [
          {
            icon: 'email-alert',
            label: 'Lista de Eventos',
            to: '/email-sent'
          }
        ]
      ]
    }
  },
  computed: {
    ...mapState([
      'isOverlayVisible'
    ])
  }
}
</script>
