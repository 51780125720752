import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import AuthRepository from './AuthRepository'
import Repository from './Repository'

const asciiDecoder = new TextDecoder('utf-8')

const query = async (payload) => {
  const client = new LambdaClient({
    region: Repository.region,
    credentials: AuthRepository.getCredential()
  })
  const response = await client.send(new InvokeCommand({
    FunctionName: process.env.VUE_APP_LAMBDA_FUNCTION_NAME,
    Payload: JSON.stringify(payload)
  }))
  const decoded = asciiDecoder.decode(response.Payload)
  const data = JSON.parse(decoded)
  if (response.FunctionError) {
    throw new Error(data.errorMessage)
  } else if (data.statusCode !== 200) {
    throw new Error(data.body)
  } else {
    return JSON.parse(data.body)
  }
}

export default {
  async getMetrics (params) {
    const payload = {
      type: 'metric',
      params
    }
    return query(payload)
  },
  async getEventData (params) {
    const payload = {
      type: 'event',
      params
    }
    return query(payload)
  }
}
