const Repository = {
  region: process.env.VUE_APP_AWS_REGION,
  cognitoLogin: process.env.VUE_APP_COGNITO_LOGIN_URL,
  events: {
    types: { Send: 'Enviados', Bounce: 'Rebotados', Delivery: 'Recepcionados', Complaint: 'Reclamados', Open: 'Abiertos' },
    default: 'Send'
  },
  periods: {
    dayInMs: (24 * 60 * 60 * 1000),
    maxDays: 30
  },
  generateCSV (filename, data) {
    let lines = ''
    data.forEach(row => {
      lines += row.join(';') + '\n'
    })
    const anchor = document.createElement('a')
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(lines)
    anchor.target = '_blank'
    anchor.download = filename + '.csv'
    anchor.click()
  }
}

export default Repository
