<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Lista de Eventos
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">

      <b-modal :active.sync="isModalActive" has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title"> {{ filters.event_type === 'Send'? 'Eventos':'Detalle de evento' }} </p>
          </header>
          <section class="modal-card-body" v-for="(detail, idx) in details" :key="idx">
            <b-field grouped group-multiline v-for="(value, field) in detail" :key="field">
              <label class="label label-for-header">{{ field }}</label>
              {{ value }}
            </b-field>
          </section>
        </div>
      </b-modal>

      <card-component title="Filtros de evento" icon="filter">
        <form @submit.prevent="validateQuery" method="POST" class="columns is-vcentered">
          <b-field label="Receptor" class="column">
            <b-input icon="email" type="text" v-model="form.mail" placeholder="Correo" name="email"/>
          </b-field>
          <b-field label="Nombre" class="columm">
            <b-input icon="account" type="text" v-model="form.name" placeholder="Nombre" name="name"/>
          </b-field>
          <b-field label="Tipo Documento" class="column">
            <b-input icon="file-document" type="text" v-model="form.document_type" placeholder="Tipo Documento" name="document_type"/>
          </b-field>
          <b-field label="Nro Documento" class="column">
            <b-input icon="barcode" type="text" v-model="form.document_number" placeholder="Nro Documento" name="document_number"/>
          </b-field>
          <b-field label="Periodo" class="column">
            <b-datepicker v-model="form.datesLine" :max-date="maxDate" range></b-datepicker>
          </b-field>
          <b-field label="Tipo de evento" class="column">
            <b-select :placeholder="form.event_type" v-model="form.event_type">
              <option v-for="(event, rawEvent) in eventTypes" :value="rawEvent" :key="rawEvent">
                {{ event }}
              </option>
            </b-select>
          </b-field>

          <div class="column search-button">
            <b-button native-type="submit" class="button is-black" :class="{'is-loading':isLoading}" icon-left="file-search-outline">
              Buscar
            </b-button>
          </div>
        </form>
      </card-component>

      <card-component title="Eventos por periodo" icon="calendar-outline" hasButtonSlot>
        <template v-slot:button >
          <div class="save-csv-button" v-if="mailEvents.length > 0">
            <b-button type="is-primary" class="is-info is-small" @click="saveToCSV" icon-left="content-save-outline">
              Guardar CSV
            </b-button>
          </div>
        </template>
        <b-table
          :paginated="paginated"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="timestamp"
          default-sort-direction="asc"
          :debounce-search="1000"
          :data="mailEvents">
          <template slot-scope="props">
            <b-table-column class="has-no-head-mobile is-image-cell">
            </b-table-column>
            <b-table-column label="Receptor" field="recipient" searchable sortable>
              {{ props.row.recipient }}
            </b-table-column>
            <b-table-column label="Fecha" field="timestamp" sortable>
              {{ parseDateForTable(props.row.timestamp) }}
            </b-table-column>
            <b-table-column label="Nombre" field="NOMBRE" searchable sortable>
              {{ props.row.NOMBRE }}
            </b-table-column>
            <b-table-column label="Tipo Documento" field="TIPO_DOC" searchable sortable>
              {{ props.row.TIPO_DOC }}
            </b-table-column>
            <b-table-column label="Nro Documento" field="NRO_DOC" searchable sortable>
              {{ props.row.NRO_DOC }}
            </b-table-column>
            <b-table-column label="Detalle" field="detail">
              <b-button type="is-primary" icon-right="eye" @click="() => showDetailsModal(props.row.details)"/>
            </b-table-column>
          </template>
        </b-table>
        <b-loading :is-full-page="false" :active.sync="isLoading"/>
      </card-component>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import Repository from '@/services/Repository'
import DataRepository from '@/services/DataRepository'

export default {
  name: 'events',
  components: {
    HeroBar,
    TitleBar,
    CardComponent
  },
  data () {
    return {
      isModalActive: false,
      mailEvents: [],
      isLoading: false,
      paginated: false,
      perPage: 10,
      maxDate: new Date(),
      form: {
        mail: '',
        document_type: '',
        document_number: '',
        event_type: Repository.events.default,
        datesLine: [],
        name: ''
      },
      filters: {},
      eventTypes: Repository.events.types,
      details: null
    }
  },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Event List'
      ]
    }
  },
  mounted () {
    const dateOffset = Repository.periods.dayInMs * 2
    const daysBefore = new Date()
    daysBefore.setTime(daysBefore.getTime() - dateOffset)
    this.form.datesLine = [daysBefore, this.maxDate]
  },
  methods: {
    saveToCSV () {
      let fields = ['recipient', 'name', 'doc_type', 'doc_number', 'date']
      let rows = []
      if (this.filters.event_type === 'Send') {
        fields.push('events')
        rows = this.mailEvents.map(row => {
          const line = [row.recipient, row.NOMBRE, row.TIPO_DOC, row.NRO_DOC, row.timestamp]
          const events = row.details.map(d => d.event_type).join(' - ')
          return line.concat(events)
        })
      } else {
        fields = fields.concat(Object.keys(this.mailEvents[0].details[0]))
        rows = this.mailEvents.map(row => {
          const line = [row.recipient, row.NOMBRE, row.TIPO_DOC, row.NRO_DOC, row.timestamp]
          return line.concat(Object.values(row.details[0]))
        })
      }
      let filename = this.eventTypes[this.filters.event_type]
      filename += '_' + this.filters.datesLine.map(mDate => this.parseDateForService(mDate, '-')).join('_')
      const data = [fields].concat(rows)
      Repository.generateCSV(filename, data)
    },
    validateQuery () {
      const mDates = this.form.datesLine.map(mDate => new Date(mDate))
      const days = (mDates[1] - mDates[0]) / Repository.periods.dayInMs
      if (days > Repository.periods.maxDays - 1) {
        this.$buefy.dialog.confirm({
          type: 'is-info',
          message: `La búsqueda es mayor a ${Repository.periods.maxDays} días. Esto puede tardar varios segundos`,
          cancelText: 'Cancelar Búsqueda',
          confirmText: 'Aceptar',
          onConfirm: () => this.updateMailEvents()
        })
      } else {
        this.updateMailEvents()
      }
    },
    showDetailsModal (details) {
      this.isModalActive = true
      this.details = details
        .sort((x, y) => new Date(x.timestamp) - new Date(y.timestamp))
        .map(detail => {
          const newDetail = {}
          Object.entries(detail).forEach(([field, value]) => {
            if (field !== 'message_id') {
              if (field === 'timestamp') {
                newDetail.Date = this.parseDateForTable(value)
              } else {
                const newField = field.split('_').map(e => e[0].toUpperCase() + e.slice(1)).join(' ')
                newDetail[newField] = value
              }
            }
          })
          return newDetail
        })
      this.details = this.details.sort((x, y) => y.timestamp - x.timestamp)
    },
    parseDateForTable (mdate) {
      const datetime = new Date(mdate)
      return datetime.toString().split('GMT')[0]
    },
    parseDateForService (mdate, sep = '/') {
      const datetime = new Date(mdate)
      const dateFormat = datetime.getFullYear() + sep + (datetime.getUTCMonth() + 1) + sep + datetime.getDate()
      return dateFormat
    },
    updateMailEvents () {
      this.filters = { ...this.form }
      const params = {
        period: {
          start: this.parseDateForService(this.filters.datesLine[0]),
          end: this.parseDateForService(this.filters.datesLine[1])
        },
        fields: {
          mail: this.filters.mail,
          name: this.filters.name,
          document_type: this.filters.document_type,
          document_number: this.filters.document_number
        },
        event_type: this.filters.event_type
      }
      this.queryMailEvents(params)
    },
    queryMailEvents (params) {
      this.isLoading = true
      DataRepository.getEventData(params).then(r => {
        if (r.data) {
          if (r.data.length > this.perPage) {
            this.paginated = true
          }
          this.mailEvents = r.data
        }
      }).catch(e => {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
<style scoped>
  .label-for-header {
    padding-right: 1rem;
  }
  .search-button {
    padding-top: 1.9em;
  }
  .save-csv-button {
    margin-right: 0.7rem;
    margin-top: 0.7em;
  }
</style>
