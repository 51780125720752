<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <hero-bar :has-right-visible="true">
      Dashboard
      <div slot="right">
        Ultima actualización: {{ lastUpdate }}
      </div>
    </hero-bar>
    <section class="section is-main-section">
      <card-component title="Metricas por fechas" icon="chart-line">
        <div class="buttons has-addons filter-for-card">
          <b-field grouped group-multiline>
            <label class="label label-for-line-chart">Fechas</label>
            <b-datepicker
              class="datepicker-for-linechart"
              v-model="datesLine"
              v-on:input="fillChartData"
              :max-date="maxDate"
             range>
            </b-datepicker>
          </b-field>
        </div>
        <div v-if="defaultChart.chartData" class="chart-area">
          <line-chart style="height: 100%"
            class="line-chart-margin"
            ref="bigChart"
            chart-id="big-line-chart"
            :chart-data="defaultChart.chartData"
            :extra-options="defaultChart.extraOptions">
          </line-chart>
          <div class="columns">
            <card-widget :label="metricEvents.Send" icon="email-multiple" class="columns is-centered column is-desktop is-vcentered is-one-fifth" :number="sentEmails"></card-widget>
            <doughnut-chart v-for="metricsChart in metricsCharts"
              :key="metricsChart.key"
              class="column is-one-fifth"
              ref="bigChart"
              chart-id="big-line-chart"
              :chart-data="metricsChart.chartData"
              :extra-options="metricsChart.extraOptions">
            </doughnut-chart>
          </div>
        </div>
        <b-loading :is-full-page="false" :active.sync="isLoading"/>
      </card-component>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import * as chartConfig from '@/components/Charts/chart.config'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import LineChart from '@/components/Charts/LineChart'
import DoughnutChart from '@/components/Charts/DoughnutChart'
import CardComponent from '../components/CardComponent.vue'
import CardWidget from '@/components/CardWidget'
import Repository from '@/services/Repository'
import DataRepository from '@/services/DataRepository'

export default {
  name: 'home',
  components: {
    LineChart,
    DoughnutChart,
    HeroBar,
    TitleBar,
    CardComponent,
    CardWidget
  },
  data () {
    return {
      defaultChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsMain
      },
      metricsCharts: [],
      isLoading: true,
      datesLine: [],
      maxDate: new Date(),
      sentEmails: 0,
      lastUpdate: '',
      metricEvents: Repository.events.types
    }
  },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Dashboard'
      ]
    }
  },
  mounted () {
    const dateOffset = Repository.periods.dayInMs * 7
    const daysBefore = new Date()
    daysBefore.setTime(daysBefore.getTime() - dateOffset)
    this.datesLine = [daysBefore, this.maxDate]
    this.fillChartData()
    this.$buefy.snackbar.open({
      message: 'Welcome back',
      queue: false
    })
  },
  methods: {
    formatMetrics (dataJson) {
      let { Send, ...metricEvents } = this.metricEvents
      metricEvents = Object.entries(metricEvents)
      const metricsData = metricEvents.map(([rawEvent, event]) => ({ key: event, values: dataJson.events[rawEvent] }))
      const labels = dataJson.dates
      const summary = metricEvents.map(([rawEvent, event]) => ({
        label: event,
        rate: dataJson.summary[rawEvent] ? dataJson.summary[rawEvent] / dataJson.summary.Send : 0
      }))
      this.sentEmails = dataJson.summary.Send
      this.lastUpdate = dataJson.last_update
      return { summary, labels, metricsData }
    },
    parseDateForService (mdate) {
      const datetime = new Date(mdate)
      const dateFormat = datetime.getFullYear() + '/' + (datetime.getUTCMonth() + 1) + '/' + datetime.getDate()
      return dateFormat
    },
    fillChartData () {
      this.isLoading = true
      const colors = [
        'rgba(255, 99, 132, 0.9)',
        'rgba(54, 162, 235, 0.9)',
        'rgba(255, 206, 86, 0.9)',
        'rgba(75, 192, 192, 0.9)',
        'rgba(153, 102, 255, 0.9)',
        'rgba(255, 159, 64, 0.9)'
      ]
      const datasets = []

      this.isLoading = true

      const params = {
        start_date: this.parseDateForService(this.datesLine[0]),
        end_date: this.parseDateForService(this.datesLine[1])
      }
      DataRepository.getMetrics(params).then(res => {
        const { summary, labels, metricsData } = this.formatMetrics(res)
        metricsData.forEach((el, idx) => {
          datasets.push(this.lineChart(el.key, el.values, colors[idx]))
        })
        this.defaultChart.chartData = {
          datasets: datasets,
          labels
        }
        this.metricsCharts = []
        summary.forEach((metric, idx) => {
          this.metricsCharts.push({
            ...this.doughnutChart(metric.rate, metric.label, colors[idx]),
            key: idx
          })
        })
      }).catch(e => {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      }).finally(() => { this.isLoading = false })
    },
    doughnutChart (rate, label, color) {
      const value = Math.floor(rate * 100)
      const difference = 100 - value
      const whitish = '#f8f8f8'
      return {
        chartData: {
          datasets: [{
            data: [value, difference],
            backgroundColor: [color, whitish]
          }],
          labels: [label]
        },
        extraOptions: {
          rotation: 1.5 * Math.PI,
          tooltips: {
            enable: false
          },
          elements: {
            center: {
              text: (rate * 100).toFixed(2) + '%'
            }
          }
        }
      }
    },
    lineChart (label, data, color) {
      return {
        fill: false,
        data: data,
        borderColor: color,
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: color,
        pointBorderColor: 'rgba(255,255,255,0)',
        pointHoverBackgroundColor: color,
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
        lineTension: 0.2,
        label: label
      }
    }
  }
}
</script>
<style scoped>
  .filter-for-card {
    padding-left: 2rem;
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }
  .line-chart-margin {
    margin-bottom: 1.5rem;
  }
  .label-for-line-chart {
    padding-right: 1rem;
  }
  .datepicker-for-linechart {
    margin-top: -.3rem;
    padding-bottom: .3rem;
    width: 14.75em;
  }
</style>
