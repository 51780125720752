import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import Repository from './Repository'

const cognitoId = `cognito-idp.${Repository.region}.amazonaws.com/${process.env.VUE_APP_USER_POOL_ID}`
const identityPoolId = process.env.VUE_APP_IDENTITY_POOL_ID
const clientId = process.env.VUE_APP_CLIENT_ID

export default {
  getToken (body) {
    const provider = new CognitoIdentityProvider({ region: Repository.region })
    const params = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: clientId,
      AuthParameters: {
        USERNAME: body.username,
        PASSWORD: body.password
      }
    }
    return provider.initiateAuth(params)
  },
  getCredential () {
    return fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: Repository.region }),
      identityPoolId,
      logins: {
        [cognitoId]: localStorage.getItem('token')
      }
    })
  }
}
